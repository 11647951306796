@keyframes wobble {
  0% {
    transform: rotateY(0deg);
  }
  25% {
    transform: rotateY(15deg);
  }
  50% {
    transform: rotateY(-15deg);
  }
  75% {
    transform: rotateY(15deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

.App {
  text-align: center;
}

.App-logo {
  width: 100px; /* Set the desired width */
  height: auto; /* Allow the height to adjust proportionally */
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
}

@media (max-width: 500px) {
  .big-logo {
    position: absolute;
    top: 12vh; /* Adjusted top position for smaller screens */
    left: calc(50vw - 128px); /* Adjusted left position for smaller screens */
    width: 70vw;
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
  }
}

@media (min-width: 500px) {
  .big-logo {
    position: absolute;
    top: 5.5vh; /* Adjusted top position for larger screens */
    left: calc(50vw - 256px); /* Adjusted left position for larger screens */
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
  }
}

/* Check if the user prefers reduced motion */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: wobble infinite 2s ease-in-out;
  }
  .big-logo {
    animation: wobble infinite 2s ease-in-out;
  }
}

.App-header {
  overflow: hidden;
  background-color: #7bc5ea00;
  padding: 20px; /* Add padding to create space around the content */
  display: flex;
  justify-content: space-between; /* Distribute the items evenly along the main axis */
  align-items: center; /* Center the items vertically */
}

.navbar {
  display: flex;
  align-items: center; /* Center the items vertically */
  justify-content: center; /* Center the items horizontally */
  /* border: 2px solid red; */
}

.navbar-brand {
  font-size: 24px;
  color: white;
  text-decoration: none;
}

.navbar-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex; /* Use flexbox to arrange items horizontally */
}

.nav-item {
  padding-left: 10px;
  padding-right: 10px;
}

.nav-link {
  color: white;
  text-decoration: none;
}

.App-content {
  max-width: 960px; /* Limit the width of the content */
  margin: 20px auto; /* Center the content horizontally */
}

.App-link {
  color: #61dafb;
}

body {
  overflow: hidden;
  background-color: #a1eebd;
  background: linear-gradient(
    to bottom,
    #7bc5ea,
    #7bc5ea,
    #7bd4ea,
    #7bd4ea,
    #a1eebd,
    #f6f7c4,
    #0040ff
  );
  background-size: 100vw 100vh; /* Ensure the gradient covers the full height of the viewport */
  transition: background-position 0.5s;
}

footer {
  overflow: hidden;
  font-size: 12px;
  color: white;
  text-decoration: none;
  position: absolute;
  bottom: 1%;
  padding-left: 1%;
}
